@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  scroll-behavior: smooth;
  overflow-x: hidden !important;
}

.scroll_snap {
  scroll-snap-type: x mandatory;
}

.scroll_item {
  scroll-snap-align: center;
}

.active_class {
  color: rgb(203 213 225) !important;
}

.grid_special {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3rem;
}

.custom_shape {
  width: 100%; /* Adjust the width */
  height: 200px; /* Adjust the height */
  background-color: #fff; /* Adjust the background color */
  -webkit-clip-path: polygon(100% 63%, 0 100%, 100% 100%);
  clip-path: polygon(100% 63%, 0 100%, 100% 100%);
}

.custom_shape_sm {
  -webkit-clip-path: polygon(0 35%, 100% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 35%, 100% 0, 100% 100%, 0% 100%);
}

/* Add this CSS to your stylesheet */
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.bounce-animation {
  animation: bounce 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.3);
  }
  50% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0.1);
  }
}

.pulse-shadow {
  animation: pulse 0.8s infinite alternate-reverse linear;
}

.pulse-shadow:hover {
  animation-play-state: paused;
}

@layer components {
  .active-page {
    @apply shadow-sm border-b border-primary_red pb-0 mb-0;
  }
  .grid_col-fix {
    display: grid;
    grid-template-columns: 22.5% 50% 22.5%;
    place-items: center center;
    gap: 3rem;
  }
  .grid_col-fix_sm {
    display: grid;
    grid-template-columns: 50% 50%;
    place-items: center center;
    gap: 5rem;
  }
  .grid_col_footer {
    display: grid;
    grid-template-columns: repeat(
      4,
      1fr
    ); /* Adjust the column percentages as needed */
  }

  .grid_col_footer_sm {
    display: grid;
    grid-template-columns: repeat(
      1,
      1fr
    ); /* Adjust the column percentages as needed */
  }
  .grid_col_footer_md {
    display: grid;
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Adjust the column percentages as needed */
  }
  .box_shadow {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Horizontal offset, Vertical offset, Blur radius, Color */
  }
  .box_shadow_hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Horizontal offset, Vertical offset, Blur radius, Color */
  }
  .trans_animate {
    @apply duration-150 ease-in-out transition-all;
  }
  .text_shadow_ {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  }
  .show_more_nav {
    position: absolute;
    top: 80%;
    z-index: 50;
  }
  .show_more_nav_sm {
    position: absolute;
    top: 55px;
    z-index: 50;
  }
}
