.slide-container {
    position: relative;
    width: 100%;
  }
  
  .image-container {
    position: relative;
    /* height: 100%; */
  }
  
  .slide-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity here */
  }
  